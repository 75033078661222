import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import ContentContainer from '~/src/components/ContentContainer';
import Suspended from '~/src/components/Suspended';
import { imgLoop_28Mp4, imgPlaySvg } from '~/src/images';
import { Urls } from '~/src/lib/enums/urls';
import { PlaylistItem } from '~/src/models/YouTube';
import useFetch from '~/src/overtime-lib/src/www/hooks/useFetch';
import './YouTube.scss';

const imageUrl = (url: string, width?: number) =>
	`https://images.itsovertime.com/youtube${new URL(url).pathname}?format=webp&width=${width}`;

const LargeVideo = ({ video }: { video: PlaylistItem }) => (
	<div>
		<a className="inherit LargeVideo" href={`https://youtube.com/v/${video.contentDetails.videoId}`}>
			<div>
				<img
					className="Thumbnail"
					alt={video.snippet.title}
					src={imageUrl(
						Object.values(video.snippet.thumbnails).reduce((best, t) => (t.width > best.width ? t : best), { width: 0 })
							.url,
					)}
				/>
				<img className="Play" alt="Play Video" src={imgPlaySvg} />
			</div>
			<div className="TextContainer">
				<div className="Title">{video.snippet.title}</div>
				<div className="Description">{video.snippet.description}</div>
			</div>
		</a>
	</div>
);

const SmallVideo = ({ video }: { video: PlaylistItem }) => (
	<div className="SmallVideo">
		<a href={`https://youtube.com/v/${video.contentDetails.videoId}`}>
			<div className="ThumbnailContainer">
				<img
					className="Thumbnail"
					alt={video.snippet.title}
					src={imageUrl(
						Object.values(video.snippet.thumbnails).reduce((best, t) => (t.width > best.width ? t : best), { width: 0 })
							.url,
						450,
					)}
				/>
				<img className="Play" alt="Play Video" src={imgPlaySvg} />
			</div>
		</a>
	</div>
);

const ShortVideo = ({ video }: { video: PlaylistItem }) => (
	<div>
		<a className={`inherit Video Short`} href={`https://youtube.com/v/${video.contentDetails.videoId}`}>
			<div className={`ThumbnailContainer Short`}>
				<img
					className={`Thumbnail Short`}
					alt={video.snippet.title}
					src={imageUrl(
						Object.values(video.snippet.thumbnails).reduce((best, t) => (t.width > best.width ? t : best), { width: 0 })
							.url,
						700,
					)}
				/>
			</div>

			<div className="TextContainer">
				<div className="Title">{video.snippet.title}</div>
			</div>
		</a>
	</div>
);

const _Videos = ({ channelIds, moreUrl, limit = 4 }: { channelIds: string[]; moreUrl: string; limit?: number }) => {
	const videoRef = useRef<HTMLVideoElement>();

	// Video must be set to default muted on load otherwise iphone won't autoplay
	useEffect(() => {
		videoRef.current.defaultMuted = true;
		videoRef.current.muted = true;
	}, []);

	// Retrieve youtube videos for each channel ID
	const youTubeChannelVideos = channelIds.flatMap((channelId) => {
		const fetchResult = useFetch<PlaylistItem[]>({
			url: `${Urls.ApiPlatformsYouTubeChannelsBaseUrl}/${channelId}?limit=${limit}`,
			key: 'items',
		});
		return fetchResult?.result.read();
	});

	// Sort videos by date, and only take the 4 more recent videos
	const youtubeVideos = _.orderBy(youTubeChannelVideos, (v) => v.contentDetails.videoPublishedAt, 'desc').slice(
		0,
		limit,
	);

	return (
		<div className="Videos">
			<video autoPlay={true} muted={true} loop={true} playsInline={true} ref={videoRef}>
				{/* Apple browsers want this codec */}
				{/* <source src={imgLoop_28H265Mp4} type="video/mp4; codecs=hvc1" /> */}
				{/* Chrome wants this codec */}
				{/* <source src={imgLoop_28H265Mp4} type="video/mp4; codecs=hev1.1.6.L93.B0" /> */}
				{/* <source src={imgLoop_28H265Mp4} type="video/mp4 maybe" /> */}
				{/* h264 fallback */}
				<source src={imgLoop_28Mp4} />
			</video>
			<div className="VideoOverlay"></div>
			<div className="VideosTitleContainer">
				<h2>Latest videos</h2>
				<div className="MoreLink">
					<a href={moreUrl}>
						More <FontAwesomeIcon icon={faArrowRight} />
					</a>
				</div>
			</div>
			<div className="VideosListContainer">
				<div className="VideosList">
					{youtubeVideos.map((v) => (
						<SmallVideo key={v.id} video={v} />
					))}
				</div>
			</div>
		</div>
	);
};

const _Shorts = ({ channelIds, moreUrl, limit = 5 }: { channelIds: string[]; moreUrl: string; limit?: number }) => {
	// Retrieve youtube videos for each channel ID
	const youTubeChannelVideos = channelIds.flatMap((channelId) => {
		const fetchResult = useFetch<PlaylistItem[]>({
			url: `${Urls.ApiPlatformsYouTubeChannelsBaseUrl}/${channelId}?limit=${limit}`,
			key: 'items',
		});
		return fetchResult?.result.read();
	});

	// Sort videos by date, and only take the 4 more recent videos
	const youTubeVideos = _.orderBy(youTubeChannelVideos, (v) => v.contentDetails.videoPublishedAt, 'desc').slice(
		0,
		limit,
	);

	return (
		<>
			<div className="Heading">
				<h1>Latest Shorts</h1>
				<a className="More" href={moreUrl}>
					More <FontAwesomeIcon icon={faArrowRight} />
				</a>
			</div>
			<div className="VideosListContainer">
				<div className="VideosList">
					{youTubeVideos.map((v) => (
						<ShortVideo video={v} key={v.id} />
					))}
				</div>
			</div>
		</>
	);
};

const YouTube = ({
	channelIds,
	moreUrl,
	limit = 4,
	isShorts = false,
	background = 'dark',
}: {
	channelIds: string[];
	moreUrl: string;
	limit?: number;
	isShorts?: boolean;
	background?: 'light' | 'dark';
}) => {
	return !isShorts ? (
		<Suspended fallback={<></>}>
			<div className={`YouTubeVideos ${background}`}>
				<ContentContainer>
					<_Videos channelIds={channelIds} limit={limit} moreUrl={moreUrl} />
				</ContentContainer>
			</div>
		</Suspended>
	) : (
		<Suspended fallback={<></>}>
			<div className={`YouTubeShorts ${background}`}>
				<ContentContainer>
					<_Shorts channelIds={channelIds} limit={limit} moreUrl={moreUrl} />
				</ContentContainer>
			</div>
		</Suspended>
	);
};

export default YouTube;
