import { faInstagram, faTiktok, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import React from 'react';
import Suspended from '~/src/components/Suspended';
import { imgOteLogoSvg } from '~/src/images';
import { Urls } from '~/src/lib/enums/urls';
import { OteTeam } from '~/src/models/OteTeam';
import useFetch from '~/src/overtime-lib/src/www/hooks/useFetch';
import './Footer.scss';

const Teams = () => {
	const fetchResult = useFetch<OteTeam[]>({
		url: `${Urls.ApiRestOteTeamsCurrentUrl}`,
		key: 'ote_teams',
	});
	const teams = fetchResult?.result.read();
	return (
		<>
			{sortBy(teams, 'name').map((t) => (
				<a className="inherit" href={`/teams/${t.slug}`} key={t.id}>
					{t.name}
				</a>
			))}
		</>
	);
};

const Footer = () => {
	return (
		<footer className="Footer">
			<nav>
				<div className="LogoContainer">
					<a href="/">
						<img alt="Home" width={76} height={92} src={imgOteLogoSvg} />
					</a>
				</div>

				<section>
					<div>League</div>
					<a className="inherit" href="/teams">
						Teams
					</a>
					{/* <a className="inherit" href="/standings">
						Standings
					</a> */}
					<a className="inherit" href="/schedule">
						Schedule
					</a>
					<a className="inherit" href="/scores">
						Scores
					</a>
					<a className="inherit" href="/players">
						Players
					</a>
					<a className="inherit" href="/statistics">
						Stats
					</a>
					<a className="inherit" href={Urls.BeehiivOteUrl}>
						News
					</a>
					<a className="inherit" href="/tickets">
						Ticket Info
					</a>
				</section>
				<section>
					<div>Teams</div>
					<Suspended>
						<Teams />
					</Suspended>
				</section>
				<section>
					<div>About</div>
					<a className="inherit" href="/about/story">
						Our Story
					</a>
					<a className="inherit" href="/about/leadership">
						Leadership
					</a>
					<a className="inherit" href="/about/academy">
						Academy
					</a>
					<a className="inherit" href="/about/hpd">
						HPD
					</a>
					<a className="inherit" href="/about/arena">
						Arena
					</a>
					<a className="inherit" href="/about/colleges">
						Recruiters
					</a>
					{/* <a className="inherit" href="/about/staff">
						Staff List
					</a> */}
					<a className="inherit" href={Urls.GoogleFormsGroupTickets}>
						Group Tickets
					</a>
				</section>
				<section>
					<div>Legal</div>
					<a className="inherit" href="/ticket_terms">
						Ticket Terms
					</a>
					<a className="inherit" href="/covid">
						COVID-19
					</a>
					<a className="inherit" href="https://overtime.tv/terms">
						Terms
					</a>
					<a className="inherit" href="https://overtime.tv/privacy">
						Privacy
					</a>
					<a className="inherit" href="https://overtime.tv/copyright">
						DMCA
					</a>
				</section>
				<section className="Social">
					<a className="inherit" href="https://www.instagram.com/ote/" title="Instagram">
						<FontAwesomeIcon icon={faInstagram} size="2x" />
					</a>
					<a className="inherit" href="https://www.tiktok.com/@ote?" title="TikTok">
						<FontAwesomeIcon icon={faTiktok} size="2x" />
					</a>
					<a className="inherit" href="https://twitter.com/OvertimeElite" title="X">
						<FontAwesomeIcon icon={faXTwitter} size="2x" />
					</a>
					<a className="inherit" href="https://www.youtube.com/channel/UCqSAy-3f7uOKTGJzw8RP-JQ" title="YouTube">
						<FontAwesomeIcon icon={faYoutube} size="2x" />
					</a>
				</section>
			</nav>
			<div className="LegalContainer">
				<div>&copy; {new Date().getFullYear()} Overtime Sports, Inc. All Rights Reserved.</div>
			</div>
		</footer>
	);
};
export default Footer;
