{
	"COLOR": {
		"BRAND_PRIMARY": "#FC4506",
		"BRAND_SECONDARY": "#46928F",
		"BRAND_LINK": "#46928F",
		"BRAND_PRIMARY_DARK": "#D61B00",
		"BRAND_PRIMARY_MODAL": "rgba(20,25,35,0.98)",
		"BRAND_PRIMARY_OVERLAY": "rgba(20,25,35,0.7)",
		"OFFBLACK": "#333",
		"BLACK": "#1e1e1e",
		"WHITE": "#ffffff",
		"GRAY": "#979797",
		"DARKGRAY": "#1C1E21",
		"LIGHTGRAY": "#efefef",
		"OFFWHITE": "#fafafa",
		"SHADOW": "rgba(0,0,0,0.25)",
		"INNER_SHADOW": "rgba(0,0,0,0.05)",
		"AMAZON_BLUE": "#1A98FF",
		"YOUTUBE_RED": "#ff0000"
	},
	"FONT": {
		"BRAND": "alternate-gothic-no-3-d, sans-serif",
		"MAIN": "'Roboto Flex', Helvetica Neue, Helvetica, Arial, sans-serif",
		"HIGHLIGHT": "VTF League, Helvetica Neue, Helvetica, Arial, sans-serif",
		"H1": "1.8rem",
		"H2": "1.4rem",
		"H3": "1.25rem",
		"SMALL": "0.9rem",
		"TINY": "0.7rem"
	},
	"SIZE": {
		"GAP": "var(--gap)",
		"GAP_QUARTER": "calc(var(--gap) / 4)",
		"GAP_HALF": "calc(var(--gap) / 2)",
		"GAP_2X": "calc(var(--gap) * 2)",
		"GAP_3X": "calc(var(--gap) * 3)",
		"GAP_4X": "calc(var(--gap) * 4)",
		"GAP_6X": "calc(var(--gap) * 6)",
		"ROW_GAP": "var(--row-gap)",
		"LARGE_MOBILE_WIDTH": "640px",
		"TABLET_WIDTH": "768px",
		"DESKTOP_WIDTH": "990px",
		"CONTENT_WIDTH": "990px"
	}
}
