import { isEmpty, orderBy, sortBy } from 'lodash';
import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Table from '~/src/components/Table';
import { OteGameOtePlayerOteTeam } from '~/src/models/OteGameOtePlayerOteTeam';
import { OteGameOteTeam } from '~/src/models/OteGameOteTeam';
import { OtePlayer } from '~/src/models/OtePlayer';
import { OteSeasonsOtePlayer } from '~/src/models/OteSeasonOtePlayer';
import { OteSeasonsOteTeam } from '~/src/models/OteSeasonsOteTeam';
import { OteTeam } from '~/src/models/OteTeam';
import { KeyOfType, colorFromInt } from '~/src/overtime-lib/src/www/lib';
import './BoxScore.scss';

type RowType = OteGameOtePlayerOteTeam | OteGameOteTeam | OteSeasonsOtePlayer | OteSeasonsOteTeam;

const TeamLogo = ({ team }: { team: OteTeam }) => (
	<div
		className="TeamLogo"
		style={{
			backgroundImage: team?.small_logo_path
				? `url(https://images.overtime.tv/${team?.small_logo_path}?width=80)`
				: team?.logo_path
					? `url(https://images.overtime.tv/${team?.logo_path}?width=80)`
					: null,
		}}
	>
		{!team?.small_logo_path && !team?.logo_path ? team?.team_code?.[0] : null}
	</div>
);

const PlayerProfile = ({
	ote_player,
	ote_team,
	number,
	statRank,
	headerIsCollapsed,
}: {
	ote_player: OtePlayer;
	ote_team: OteTeam;
	number: string;
	statRank?: number;
	headerIsCollapsed: boolean;
}) => (
	<>
		<div className={`RankImageContainer`}>
			{statRank ? (
				<div className="StatRank">
					<p>{statRank}</p>
				</div>
			) : null}
			<div className="ImageNumberContainer">
				{number && (
					<div className="JerseyNumber" style={{ color: colorFromInt(ote_team?.primary_color) }}>
						{number}
					</div>
				)}
				{ote_player.image_path ? (
					<img
						className="PlayerImage"
						style={{ backgroundColor: colorFromInt(ote_team?.primary_color) }}
						src={`https://images.overtime.tv/${ote_player.image_path}?width=80`}
						alt={ote_player.full_name}
					/>
				) : (
					<TeamLogo team={ote_team} />
				)}
			</div>
		</div>
		<div className={`NameContainer ${headerIsCollapsed ? 'NameContainer--FadeOut' : 'NameContainer--FadeIn'}`}>
			{/* {ote_player.first_name.slice(0, 1)}. {ote_player.family_name} */}
			<div className="Name">{`${ote_player.first_name} ${ote_player.family_name}`}</div>
		</div>
	</>
);

const defaultRowHeader = (r: RowType, statRank: number, headerIsCollapsed: boolean) => {
	const team = 'ote_team' in r ? r.ote_team : r.ote_player.ote_team;
	if ('ote_player' in r) {
		return r.ote_player.is_current && r.ote_player.is_ote && r.ote_player.ote_team.is_current ? (
			<a className="Profile" href={`/players/${r.ote_player.id}`}>
				<PlayerProfile
					ote_player={r.ote_player}
					ote_team={team}
					number={(r as any).pno ?? r.ote_player.jersey_number}
					statRank={statRank}
					headerIsCollapsed={headerIsCollapsed}
				/>
			</a>
		) : (
			<div className="Profile">
				<PlayerProfile
					ote_player={r.ote_player}
					ote_team={team}
					number={(r as any).pno ?? r.ote_player.jersey_number}
					statRank={statRank}
					headerIsCollapsed={headerIsCollapsed}
				/>
			</div>
		);
	} else if (team.is_current) {
		return (
			<a className="Profile" href={`/teams/${team.slug}/statistics`}>
				<TeamLogo team={team} />
				{headerIsCollapsed ? <div className="Name">{team.team_code}</div> : <div className="Name">{team.name}</div>}
			</a>
		);
	} else {
		return (
			<div className="Profile">
				<TeamLogo team={team} />
				{headerIsCollapsed ? <div className="Name">{team.team_code}</div> : <div className="Name">{team.name}</div>}
			</div>
		);
	}
};

function BoxScore({
	title,
	rows,
	rowHeader,
	averageRow,
	per40Row,
	isTakeover = false,
	showWinLose = false,
	showTotals = true,
	useHash = true,
	defaultHeader,
	collapsableRowHeader = false,
	showStatRank = true,
}: {
	title?: string;
	rows: RowType[];
	rowHeader?: (row: RowType) => ReactNode;
	averageRow?: OteSeasonsOtePlayer | OteSeasonsOteTeam;
	per40Row?: OteSeasonsOtePlayer;
	isTakeover?: boolean;
	showWinLose?: boolean;
	showTotals?: boolean;
	useHash?: boolean;
	defaultHeader?: string;
	collapsableRowHeader?: boolean;
	showStatRank?: boolean;
}) {
	const columnExists = (column: string) => rows.some((r) => !!r[column]);
	const hash = useLocation().hash.slice(1);
	const [headerIsCollapsed, setHeaderIsCollapsed] = useState(false);
	const toggleCollapse = () => setHeaderIsCollapsed((prev) => !prev);

	useEffect(() => {
		// if page is initially loaded in a mobile view the collapsable header will render collapsed otherwise it will default render expanded
		const handleInitialCollapseState = () => {
			if (window.innerWidth < 768) {
				setHeaderIsCollapsed(false);
			}
		};
		handleInitialCollapseState();
	}, []);

	const headers = [
		// 'pno' in firstRow && !averageRow ? ['pno', 'num'] : null,
		showWinLose ? ['wins', 'w'] : null,
		showWinLose ? ['losses', 'l'] : null,
		showTotals ? ['points', 'pts'] : null,
		columnExists('points_per_game') ? ['points_per_game', 'ppg'] : null,
		showTotals ? ['assists', 'ast'] : null,
		columnExists('assists_per_game') ? ['assists_per_game', 'apg'] : null,
		showTotals ? ['rebounds_total', 'reb'] : null,
		columnExists('rebounds_per_game') ? ['rebounds_per_game', 'rpg'] : null,
		showTotals ? ['steals', 'stl'] : null,
		columnExists('steals_per_game') ? ['steals_per_game', 'spg'] : null,
		showTotals ? ['blocks', 'blk'] : null,
		columnExists('blocks_per_game') ? ['blocks_per_game', 'bpg'] : null,
		showTotals ? ['dunks', 'dnk'] : null,
		columnExists('dunks_per_game') ? ['dunks_per_game', 'dpg'] : null,
		columnExists('games') ? ['games', 'gp'] : null,
		showTotals ? (isTakeover ? null : columnExists('minutes') ? ['minutes', 'min'] : null) : null,
		isTakeover ? null : columnExists('minutes_per_game') ? ['minutes_per_game', 'mpg'] : null,
		showTotals ? ['two_pointers_made', '2pm'] : null,
		showTotals ? ['two_pointers_attempted', '2pa'] : null,
		['two_pointers_percentage', '2p%'],
		showTotals ? ['three_pointers_made', '3pm'] : null,
		showTotals ? ['three_pointers_attempted', '3pa'] : null,
		['three_pointers_percentage', '3p%'],
		showTotals ? ['field_goals_made', 'fgm'] : null,
		showTotals ? ['field_goals_attempted', 'fga'] : null,
		['field_goals_percentage', 'fg%'],
		showTotals ? ['free_throws_made', 'ftm'] : null,
		showTotals ? ['free_throws_attempted', 'fta'] : null,
		['free_throws_percentage', 'ft%'],
		columnExists('plus_minus') ? ['plus_minus', '+/-'] : null,
		showTotals ? (isTakeover ? null : ['fouls_total', 'pf']) : null,
		isTakeover ? null : columnExists('fouls_total_per_game') ? ['fouls_total_per_game', 'pfpg'] : null,
		showTotals ? ['turnovers', 'to'] : null,
		columnExists('turnovers_per_game') ? ['turnovers_per_game', 'tpg'] : null,
	].filter(Boolean) as [KeyOfType<RowType, number>, string][];

	const averageForField = (field, row: OteSeasonsOtePlayer | OteSeasonsOteTeam) => {
		if (field === 'rebounds_total') {
			return row.rebounds_per_game;
		} else if (`${field}_per_game` in row) {
			return row[`${field}_per_game`];
		} else if (field.endsWith('percentage') && field in row) {
			return row[field];
		} else {
			return row.games ? row[field] / row.games : '-';
		}
	};
	const per40ForField = (field, row: OteSeasonsOtePlayer) => {
		if (field === 'rebounds_total') {
			return row.rebounds_per_40;
		} else if (`${field}_per_40` in row) {
			return row[`${field}_per_40`];
		} else if (field.endsWith('percentage') && field in row) {
			return row[field];
		} else {
			return row.games ? row[field] / (row.minutes / 40) : '-';
		}
	};

	const [currentHeader, setCurrentHeader] = useState<string>(defaultHeader);

	//Hash based effects need to be in useEffect to avoid SSR mismatch
	const [sortedRows, setSortedRows] = useState<RowType[]>(
		orderBy(
			rows.map((r) => ({
				...r,
				id: r.id ?? (r as any).ote_player?.id,
				family_name: (r as any).ote_player?.family_name ?? `Zzzzzz`,
			})),
			['family_name', 'first_name', 'ote_team.name', 'ote_game.starts_at'],
			['asc', 'asc', 'asc', 'desc'],
		),
	);
	useEffect(() => {
		if (!isEmpty(currentHeader)) {
			setSortedRows(
				sortBy(
					rows.map((r) => ({ ...r, [currentHeader]: r[currentHeader] ?? 0 })),
					['ote_team', currentHeader],
				).reverse(),
			);
		} else {
			setSortedRows(
				orderBy(
					rows.map((r) => ({
						...r,
						id: r.id ?? (r as any).ote_player?.id,
						family_name: (r as any).ote_player?.family_name ?? `Zzzzzz`,
					})),
					['wins', 'loses', 'family_name', 'first_name', 'ote_team.name', 'ote_game.starts_at'],
					['desc', 'asc', 'asc', 'asc', 'asc', 'desc'],
				),
			);
		}
	}, [rows, currentHeader]);

	useEffect(() => {
		useHash ? setCurrentHeader(hash) : null;
	}, [hash]);

	const ref = useRef<HTMLDivElement>();
	//Avoid React SSR throwing a warning
	if (typeof window !== 'undefined') {
		useLayoutEffect(() => {
			const nav = ref.current;
			if (!nav) {
				return;
			}
			const first = nav.querySelector('th') as HTMLTableCellElement;
			const current = nav.getElementsByClassName('current')[0] as HTMLAnchorElement;
			if (!first || !current) {
				return;
			}
			if (
				current.offsetLeft - nav.scrollLeft < first.getBoundingClientRect().width ||
				current.offsetLeft - nav.scrollLeft + current.offsetWidth > nav.getBoundingClientRect().width
			) {
				nav.scrollTo(current.offsetLeft - first.offsetWidth, 0);
			}
		}, [rows, currentHeader]);
	}

	return (
		<div className={`BoxScore${!columnExists('pno') ? ' NoNumbers' : ''}`} ref={ref}>
			<Table
				title={title}
				headers={headers.map((h) => ({ value: h[0], label: h[1] }))}
				currentHeader={currentHeader}
				onHeaderSelect={setCurrentHeader}
				collapsableRowHeader={collapsableRowHeader}
				headerIsCollapsed={headerIsCollapsed}
				collapsedHeaderClass={showStatRank ? 'Title--Collapsed--Long' : 'Title--Collapsed'}
				toggleCollapse={toggleCollapse}
				rows={[
					...sortedRows.map((row, i) => (
						<tr key={row.id ?? i}>
							<th className="LeftHeader">
								{rowHeader ? rowHeader(row) : defaultRowHeader(row, showStatRank ? i + 1 : null, headerIsCollapsed)}
							</th>
							{headers.map((h) => (
								<td key={h[0]} className={`Statistic${currentHeader === h[0] ? ' current' : ''}`}>
									{row.minutes === 0 && 'ote_game' in row
										? h[0] === 'minutes'
											? 'DNP'
											: ''
										: (h as any)[0] === 'pno' && !row[h[0]]
											? ''
											: ((h: string, v: number) =>
													h === 'minutes' ? Math.round(v) : h.includes('_per') ? v.toFixed(1) : v)(
													h[0],
													row[h[0]] ?? 0,
												)}
								</td>
							))}
						</tr>
					)),
					averageRow ? (
						<tr className="Average" key="average">
							<th>Average</th>
							{headers.map((h) => (
								<td key={h[0]} className="Statistic">
									{isNaN(averageForField(h[0], averageRow)) ? '' : (averageForField(h[0], averageRow) ?? 0).toFixed(1)}
								</td>
							))}
						</tr>
					) : null,
					per40Row ? (
						<tr className="Per40" key="per40">
							<th>Per 40</th>
							{headers.map((h) => (
								<td key={h[0]} className="Statistic">
									{(h as any)[0] === 'pno' ? '' : (per40ForField(h[0], per40Row) ?? 0).toFixed(1)}
								</td>
							))}
						</tr>
					) : null,
				]}
				useHash={useHash}
			/>
		</div>
	);
}

export default BoxScore;
