import { isNil } from 'lodash';
import React from 'react';
import { imgArrowLeftCollapseExpandSvg } from '~/src/images';
import './Table.scss';

interface TableHeader {
	value?: string;
	label: string;
	mobileLabel?: string;
}
const Table = ({
	title,
	headers,
	currentHeader,
	onHeaderSelect,
	rows,
	useHash = true,
	collapsableRowHeader = false,
	headerIsCollapsed,
	collapsedHeaderClass: collapsedClass,
	toggleCollapse,
}: {
	title?: string;
	headers: TableHeader[];
	currentHeader: string;
	onHeaderSelect?: (string) => void;
	rows: JSX.Element[];
	useHash?: boolean;
	collapsableRowHeader?: boolean;
	headerIsCollapsed?: boolean;
	collapsedHeaderClass?: string;
	toggleCollapse?: () => void;
}) => (
	<div className="Table">
		<table>
			<thead>
				<tr>
					<th
						className={`Title ${collapsableRowHeader ? (headerIsCollapsed ? collapsedClass : '') : ''}`}
						role={!isNil(headers[0]?.value) ? 'button' : undefined}
						onClick={() => {
							useHash ? window.history.replaceState({}, document.title, location.pathname) : null;
							onHeaderSelect && onHeaderSelect(null);
						}}
					>
						<div className="TitleContentContainer">
							{title}
							{collapsableRowHeader && (
								<div
									className="CollapseExpandContainer"
									aria-label={headerIsCollapsed ? 'Expand header section.' : 'Collapse header section.'}
									onClick={(e) => {
										// prevent the click from propagating to the parent <th/>
										e.stopPropagation();
										toggleCollapse();
									}}
								>
									<img
										src={imgArrowLeftCollapseExpandSvg}
										alt=""
										className={`CollapseExpandIcon ${headerIsCollapsed ? 'CollapseExpandIcon--Collapsed' : 'CollapseExpandIcon--Expanded'}`}
									/>
								</div>
							)}
						</div>
					</th>
					{headers.map((h) => (
						<th
							key={h.value ?? h.label}
							role={!isNil(h?.value) ? 'button' : undefined}
							onClick={() => {
								if (isNil(h.value)) {
									return;
								}
								useHash ? window.history.replaceState({}, document.title, location.pathname + '#' + h.value) : null;
								onHeaderSelect(h.value);
							}}
							className={currentHeader === h.value ? 'current' : null}
						>
							<span className="mobile-only">{h.mobileLabel ?? h.label}</span>
							<span className="desktop-only">{h.label}</span>
						</th>
					))}
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</table>
	</div>
);

export default Table;
