import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faTicket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, isNil } from 'lodash';
import React, { useState } from 'react';
import { imgPrimeLogoGraySvg, imgPrimeLogoSvg } from '~/src/images';
import { Urls } from '~/src/lib/enums/urls';
import { OteEvent } from '~/src/models/OteEvent';
import {
	OteGame,
	getSeriesGame,
	isComplete,
	isLive,
	isLiveStreaming,
	isNeeded,
	isPlayoffs,
	isScheduled,
} from '~/src/models/OteGame';
import { isSeriesWinner } from '~/src/models/OteSeries';
import { OteSeriesOteTeam } from '~/src/models/OteSeriesOteTeam';
import { OteTeam } from '~/src/models/OteTeam';
import { EventInfoResponse, SaleStatuses } from '~/src/models/Vivenu';
import FormattedTimestamp from '~/src/overtime-lib/src/www/components/FormattedTimestamp';
import useFetch from '~/src/overtime-lib/src/www/hooks/useFetch';
import { capitalize, colorFromInt } from '~/src/overtime-lib/src/www/lib';
import './GameCard.scss';

const getVivenuEventIsSoldOut = (event: OteEvent) => {
	if (!event?.vivenu_id) {
		return false;
	}
	const vivenuEvent = useFetch<EventInfoResponse>({
		url: `${Urls.ApiPlatformsVivenuEventInfo}/${event.vivenu_id}`,
		key: 'event',
	})?.result.read();

	return vivenuEvent?.saleStatus === SaleStatuses.SoldOut;
};

const isEventSoldOut = (ote_event: OteEvent) => ote_event?.is_sold_out || getVivenuEventIsSoldOut(ote_event);

const _Team = ({
	ote_team,
	ote_game,
	isLoser,
	showFullTeamName,
	ote_series_ote_team,
}: {
	ote_team: OteTeam;
	ote_game: OteGame;
	isLoser: boolean;
	showFullTeamName: boolean;
	ote_series_ote_team: OteSeriesOteTeam;
}) => {
	const [currentSeason, setCurrentSeason] = useState(
		// TODO: We need to add a useEffect on the incoming team to update the current season standings
		// When a game completes and their record is updated (this only happens at the end of the game
		// -- so this is a lower priority issue to fix since it's not as noticeable)
		// But do we want to include the season team info in every single update throughout the game?
		ote_team?.ote_seasons_ote_teams?.find((s) => s.ote_season_id === ote_game.ote_season_id),
	);

	return (
		<div className={`TeamContainer${showFullTeamName ? ' FullTeamName' : ''}`}>
			<div
				className={`TeamLogo`}
				style={{
					backgroundColor: colorFromInt(ote_team?.primary_color),
					backgroundImage: ote_team?.small_logo_path
						? `url(https://images.overtime.tv/${ote_team?.small_logo_path}?width=80&format=webp)`
						: ote_team?.logo_path
							? `url(https://images.overtime.tv/${ote_team?.logo_path}?width=80&format=webp)`
							: null,
				}}
			>
				{!ote_team?.small_logo_path && !ote_team?.logo_path ? ote_team?.team_code?.at(0) : null}
			</div>

			<div className={`desktop-only TeamCode`}>
				{!ote_team
					? 'TBD'
					: showFullTeamName
						? ote_team.name
						: ote_team?.team_code ?? ote_team?.name?.slice(0, 3) ?? 'TBD'}
			</div>

			<div className={`mobile-only TeamCode`}>{ote_team?.team_code ?? ote_team?.name?.slice(0, 3) ?? 'TBD'}</div>

			{ote_game.ote_season?.name.includes('Playoffs') && ote_game.ote_series_id && ote_series_ote_team ? (
				<>
					{/* Desktop will show Adv only if on wide screen layout -- which is only if show full team name is true*/}
					<div className="desktop-only SeriesScore">
						{isSeriesWinner(ote_series_ote_team, ote_game.ote_series)
							? ote_game.ote_series.name == 'Finals'
								? `Wins ${ote_series_ote_team.series_wins}-${ote_series_ote_team.series_losses}`
								: `${showFullTeamName ? 'Advances' : 'Adv'} ${ote_series_ote_team.series_wins}-${ote_series_ote_team.series_losses}`
							: `${ote_series_ote_team.series_wins}-${ote_series_ote_team.series_losses}`}
					</div>

					{/* Mobile will always show abbreviated Adv */}
					<div className="mobile-only SeriesScore">
						{isSeriesWinner(ote_series_ote_team, ote_game.ote_series)
							? ote_game.ote_series.name == 'Finals'
								? `Wins ${ote_series_ote_team.series_wins}-${ote_series_ote_team.series_losses}`
								: `Adv ${ote_series_ote_team.series_wins}-${ote_series_ote_team.series_losses}`
							: `${ote_series_ote_team.series_wins}-${ote_series_ote_team.series_losses}`}
					</div>
				</>
			) : ote_game.ote_season?.name.includes('Season') && !ote_game.ote_series_id && currentSeason ? (
				<div className="SeriesScore">
					{currentSeason.wins}-{currentSeason.losses}
				</div>
			) : (
				<div className="SeriesScore">&nbsp;</div>
			)}
		</div>
	);
};

const _BroadcastTime = ({ ote_game, isAbbreviated = false }: { ote_game: OteGame; isAbbreviated?: boolean }) => {
	return (
		<>
			{isPlayoffs(ote_game) ? (
				<>
					{!isLive(ote_game) ? (
						<>
							<FormattedTimestamp timestamp={ote_game.starts_at} format={'ccc, LLL d'} />
							{!isComplete(ote_game) && ote_game.expected_broadcast == 'Prime Video'
								? ' on Prime'
								: !isComplete(ote_game) && ote_game.expected_broadcast == 'YouTube'
									? ' on YouTube'
									: ''}
						</>
					) : (
						<>
							<div className="LiveStreamIndicator"></div> Live
						</>
					)}
					<> — {getSeriesGame(ote_game, isAbbreviated)}</>
				</>
			) : isLive(ote_game) ? (
				<>
					<div className="LiveStreamIndicator"></div> Live
				</>
			) : ote_game.starts_at ? (
				<div
					className={`BroadcastTime${ote_game.primary_color ? ' Pill' : ''}`}
					style={
						ote_game.primary_color && colorFromInt(ote_game.primary_color) !== '#ffffff'
							? {
									color: '#fff',
									background: `linear-gradient(180deg, ${colorFromInt(ote_game.secondary_color)} 0%, ${colorFromInt(ote_game.primary_color)} 50%, ${colorFromInt(ote_game.secondary_color)} 100%)`,
								}
							: undefined
					}
				>
					<FormattedTimestamp timestamp={ote_game.starts_at} format={'ccc, LLL d'} />
					{ote_game.game_category == 'Preseason' && <> — Preseason</>}
					{!isComplete(ote_game) && ote_game.expected_broadcast == 'Prime Video'
						? ' on Prime'
						: !isComplete(ote_game) && ote_game.expected_broadcast == 'YouTube'
							? ' on YouTube'
							: ''}
				</div>
			) : (
				'TBD'
			)}
		</>
	);
};

const _CtaLink = ({ ote_game, linkToGamePage = true }: { ote_game: OteGame; linkToGamePage?: boolean }) => {
	return (
		<div className="CtaContainer">
			{(isLiveStreaming(ote_game) || isComplete(ote_game)) && ote_game.youtube_id ? (
				<a className="Tickets Youtube" href={`${Urls.YouTubeVideoBaseUrl}?v=${ote_game.youtube_id}`}>
					<FontAwesomeIcon icon={faYoutube} /> Watch {isLiveStreaming(ote_game) ? 'Live' : 'Replay'}
				</a>
			) : (isLiveStreaming(ote_game) || isComplete(ote_game)) && ote_game.prime_video_id ? (
				<a className="Tickets Prime" href={`${Urls.AmazonPrimeVideoBaseUrl}/${ote_game.prime_video_id}`}>
					<img src={imgPrimeLogoSvg} alt="Watch on Prime" /> Watch {isLiveStreaming(ote_game) ? 'Live' : 'Replay'}
				</a>
			) : isScheduled(ote_game) && isEventSoldOut(ote_game.ote_event) ? (
				<div className="Tickets SoldOut">Sold Out</div>
			) : isScheduled(ote_game) && ote_game.ote_event?.cta_url ? (
				<a className="Tickets" href={ote_game.ote_event?.cta_url}>
					<FontAwesomeIcon icon={faTicket} /> Tickets
				</a>
			) : isScheduled(ote_game) && ote_game.ote_event?.vivenu_id ? (
				<a className="Tickets" href={`${Urls.VivenuTicketsEventBaseUrl}/${ote_game.ote_event.vivenu_id}`}>
					<FontAwesomeIcon icon={faTicket} /> Tickets
				</a>
			) : isScheduled(ote_game) && (ote_game.youtube_id || ote_game.expected_broadcast == 'YouTube') ? (
				<a
					href={linkToGamePage ? `/games/${ote_game.id}/box_score` : null}
					className="Tickets Youtube Youtube--disabled"
				>
					<FontAwesomeIcon icon={faYoutube} /> Watch Soon
				</a>
			) : isScheduled(ote_game) && ote_game.prime_video_id && ote_game.expected_broadcast == 'Prime Video' ? (
				<a className="Tickets Prime" href={`${Urls.AmazonPrimeVideoBaseUrl}/${ote_game.prime_video_id}`}>
					<img src={imgPrimeLogoSvg} alt="Watch on Prime" /> Watch Soon
				</a>
			) : isScheduled(ote_game) && ote_game.prime_video_id == null && ote_game.expected_broadcast == 'Prime Video' ? (
				<a href={linkToGamePage ? `/games/${ote_game.id}/box_score` : null} className="Tickets Prime Prime--disabled">
					<img src={imgPrimeLogoGraySvg} alt="Watch on Prime" /> Watch Soon
				</a>
			) : // ) : showLocation && ote_game.address && !isComplete(ote_game) ? (
			// 	<a
			// 		className="Tickets"
			// 		href={`https://www.google.com/maps/place/${ote_game.address},${ote_game.city},${ote_game.state}+${ote_game.zip}`}
			// 	>
			// 		<FontAwesomeIcon icon={faLocationPin} />
			// 		{ote_game.city}, {abbr(ote_game.state)}
			// 	</a>
			linkToGamePage ? (
				<a className="Tickets" href={`/games/${ote_game.id}/box_score`}>
					Details
				</a>
			) : null}
		</div>
	);
};

const _GameCard = ({ ote_game, showFullTeamName }: { ote_game: OteGame; showFullTeamName: boolean }) => {
	const hasScore = isLive(ote_game) || (isComplete(ote_game) && ote_game.ote_games_ote_teams?.some((g) => g.score > 0));
	const gameHomeTeam = ote_game.ote_games_ote_teams?.find((t) => t.is_home_team);
	const gameAwayTeam = ote_game.ote_games_ote_teams?.find((t) => !t.is_home_team);
	const isAwayLoser = isComplete(ote_game) && gameAwayTeam?.score < gameHomeTeam?.score;
	const isHomeLoser = isComplete(ote_game) && gameAwayTeam?.score > gameHomeTeam?.score;
	const seriesHomeTeam = ote_game.ote_series?.ote_series_ote_teams?.find(
		(t) => t.ote_team_id === gameHomeTeam?.ote_team.id,
	);
	const seriesAwayTeam = ote_game.ote_series?.ote_series_ote_teams?.find(
		(t) => t.ote_team_id === gameAwayTeam?.ote_team.id,
	);

	const status = (() => {
		if (ote_game.status === 'in_progress') {
			if (
				ote_game.period === 3 &&
				(ote_game.ote_games_ote_teams?.at(0)?.ote_game_ote_team_periods?.length === 2 || isNil(ote_game.game_clock))
			) {
				return 'Half';
			}
			return ote_game.period > 10
				? `OT${ote_game.period % 10 === 1 ? '' : ote_game.period % 10}`
				: ote_game.period
					? `Q${ote_game.period}`
					: '';
		}

		switch (ote_game.status) {
			case 'cancelled':
			case 'postponed':
			case 'bye':
				return capitalize(ote_game.status);
			case 'finished':
			case 'confirmed':
				return ote_game.ote_games_ote_teams?.at(0)?.ote_game_ote_team_periods?.find((p) => p.period_id >= 10)
					? 'Final/OT'
					: 'Final';
			case 'scheduled':
			case 'pending':
			case 'if_needed':
			default:
				return isComplete(ote_game)
					? 'Final'
					: !isEmpty(ote_game.location_name)
						? ote_game.location_name
						: ote_game.address?.includes('230 17th St') && ote_game.city === 'Atlanta'
							? 'OTE Arena'
							: !isEmpty(ote_game.purpose)
								? ote_game.purpose
								: '';
		}
	})();

	return (
		<>
			<div className={`DateContainer`}>
				<div className={`Date${isPlayoffs(ote_game) ? ' Playoffs' : ''}${isLive(ote_game) ? ' Live' : ''}`}>
					<_BroadcastTime ote_game={ote_game} isAbbreviated={!showFullTeamName && isScheduled(ote_game)} />
				</div>
			</div>
			<div className={`TeamsContainer`}>
				<_Team
					ote_team={gameAwayTeam?.ote_team}
					ote_game={ote_game}
					isLoser={isAwayLoser}
					showFullTeamName={showFullTeamName}
					ote_series_ote_team={seriesAwayTeam}
				/>
				<div className={`ScoreContainer${!isLive(ote_game) && !isComplete(ote_game) ? ' Pregame' : ''}`}>
					<div className={`Score${isAwayLoser ? ' Loser' : ''}`}>{hasScore ? gameAwayTeam?.score ?? 0 : null}</div>
					<div className="StatusContainer">
						<div className="Status">{status}</div>
						<div className="Time">
							{ote_game.status === 'in_progress' ? (
								!ote_game.game_clock || status === 'Half' ? (
									''
								) : (
									`${Math.floor(ote_game.game_clock / 1000 / 60)}:${String((ote_game.game_clock / 1000) % 60).padStart(
										2,
										'0',
									)}`
								)
							) : isComplete(ote_game) ? (
								''
							) : ote_game.starts_at ? (
								<FormattedTimestamp timestamp={ote_game.starts_at} format={'h:mma ZZZZ'} />
							) : (
								''
							)}
						</div>
						{isNeeded(ote_game) ? <div className="IfNeeded">If Necessary</div> : null}
					</div>
					<div className={`Score${isHomeLoser ? ' Loser' : ''}`}>{hasScore ? gameHomeTeam?.score ?? 0 : null}</div>
				</div>
				<_Team
					ote_team={gameHomeTeam?.ote_team}
					ote_game={ote_game}
					isLoser={isHomeLoser}
					showFullTeamName={showFullTeamName}
					ote_series_ote_team={seriesHomeTeam}
				/>
			</div>
		</>
	);
};

const GameCard = ({
	ote_game,
	linkToGamePage = true,
	showFullTeamName = true,
}: {
	ote_game: OteGame;
	linkToGamePage?: boolean;
	showFullTeamName?: boolean;
}) => {
	const hideStatuses = ['cancelled', 'postponed', 'bye'];
	linkToGamePage = linkToGamePage && !hideStatuses.includes(ote_game.status);

	return (
		<div className={`GameCard`}>
			{linkToGamePage ? (
				<>
					<a className={`GameCardContainer`} href={linkToGamePage ? `/games/${ote_game.id}/box_score` : null}>
						<_GameCard ote_game={ote_game} showFullTeamName={showFullTeamName} />
					</a>
					<_CtaLink ote_game={ote_game} linkToGamePage={linkToGamePage} />
				</>
			) : (
				<>
					<div className={`GameCardContainer`}>
						<_GameCard ote_game={ote_game} showFullTeamName={showFullTeamName} />
					</div>
					<_CtaLink ote_game={ote_game} linkToGamePage={linkToGamePage} />
				</>
			)}
		</div>
	);
};

export default GameCard;
